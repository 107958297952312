import { client } from "./data"

export const addBookingToCheckout = async (variantId, quantity, properties) => {
  const checkout = await client.checkout.create()
  const customAttributtes = []
  Object.entries(properties).forEach(([key, value]) => {
    customAttributtes.push({ key: key, value: value })
  })
  await client.checkout.addLineItems(checkout.id, [
    {
      variantId,
      quantity: parseInt(quantity, 10),
      customAttributes: customAttributtes,
    },
  ])
  return checkout.webUrl
}
