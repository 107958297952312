import React from "react"
import { useState } from "react"
import useReservations from "../../hooks/useReservations"
import { LayoutSection, PriceLayout, SelectionPill } from "../common/layout"
import { Counter } from "../common/selection"
import DatePicker from "./datePicker"
import TimePicker from "./timePicker"
import { SimplePaddedCard } from "../common/cards"
import { GatsbyImage } from "gatsby-plugin-image"
import { useEffect } from "react"
import { PastelButton } from "../common/button"
import {
  checkIfPastHour,
  checkOverlap,
  generateReservationObject,
  numberToDia,
} from "../../util/data"
import { addBookingToCheckout } from "../../util/checkout"
import { Cart, Loader } from "../common/icons"
import { Toast } from "../common/toasts"
import configData from "../../data/fields"

const getSelectedAndToday = (date, leadTime) => {
  const now = new Date()
  now.setMinutes(now.getMinutes() + leadTime - 300 + now.getTimezoneOffset())
  const selected = new Date(date.toLocaleDateString())
  const today = new Date(now.toLocaleDateString())
  return { now, selected, today }
}

const ReservationLayout = ({ data }) => {
  const timeOptions = data.options.find(({ name }) => name === "Tiempo").values
  const [quantity, setQuantity] = useState(1)
  const [variant, setVariant] = useState()
  const [date, setDate] = useState()
  const [selectedDuration, setSelectedDuration] = useState(timeOptions[timeOptions.length - 1])
  const [time, setTime] = useState()
  const [slots, setSlots] = useState([])
  const [refresh, setRefresh] = useState()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState()
  const {
    getHolidays,
    capacity,
    events,
    availability,
    specialDates,
    timezone,
    operator,
    checkDay,
    employeeId,
    resolution,
    bookings,
    leadTime,
    startDate,
    endDate,
  } = useReservations({
    productId: data.shopifyId,
    userId: configData.config.eventEmployeeId,
  })

  const [variantMapping, setVariantMapping] = useState()

  useEffect(() => {
    const variantMapping = {}
    for (let i = 0; i < data.variants.length; i++) {
      const variant = data.variants[i]
      const options = {}
      for (let { name, value } of variant.selectedOptions) options[name] = value
      if (!variantMapping[options.Tiempo]) variantMapping[options.Tiempo] = {}
      variantMapping[options.Tiempo] = {
        ...variantMapping[options.Tiempo],
        [options["Día"]]: i,
      }
    }
    setVariantMapping(variantMapping)
  }, [data.variants])

  useEffect(() => {
    if (!date || !availability.length) return
    const slots = []
    const day = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
      .getDate()
      .toString()
      .padStart(2, "0")}`
    const duration = events[data.variants[variant]?.shopifyId]?.duration
    const { now, selected, today } = getSelectedAndToday(date, leadTime)
    // Check if the date has already passed
    if (selected < today) return setSlots(slots)

    const times = specialDates[day] || availability[date.getDay()]
    for (let time of times) {
      const { start, end } = time
      if ((start.hh === end.hh && start.mm === end.mm) || !duration) continue
      for (let i = start.hh * 60 + start.mm; i + duration < end.hh * 60 + end.mm; i += resolution) {
        const mms = i % 60
        const hhs = parseInt(i / 60)
        // Check if the time slot has already started
        if (
          selected.valueOf() === today.valueOf() &&
          checkIfPastHour({ hh: hhs, mm: mms }, { hh: now.getHours(), mm: now.getMinutes() })
        )
          continue

        // The time slot has yet to come
        const mme = (mms % 60) + duration
        const hhe = hhs + parseInt(mme / 60)
        const slot = {
          start: {
            hh: hhs,
            mm: mms,
          },
          end: {
            hh: hhe,
            mm: mme % 60,
          },
        }
        const takenSeats =
          bookings[date]?.reduce(
            (sum, booking) => sum + (checkOverlap(slot, booking) ? booking.quantity : 0),
            0
          ) || 0
        slots.push({
          ...slot,
          capacity: takenSeats <= capacity ? capacity - takenSeats : 0,
        })
      }
    }

    setSlots(slots)
  }, [
    variant,
    data.variants,
    date,
    availability,
    bookings,
    refresh,
    capacity,
    events,
    leadTime,
    resolution,
    specialDates,
  ])

  useEffect(() => {
    if (!date || !employeeId) return
    checkDay(date)
  }, [date, employeeId, checkDay])

  useEffect(() => {
    if (!date || !variantMapping) return
    const day = numberToDia[date.getDay()]
    if (variant !== variantMapping[selectedDuration][day])
      setVariant(variantMapping[selectedDuration][day])
  }, [selectedDuration, date, variantMapping, variant])

  return (
    <LayoutSection>
      <div className="w-full mx-auto max-w-5xl flex flex-col px-2 py-8 sm:px-8 gap-4">
        <SimplePaddedCard>
          <h2 className="text-4xl font-bold">Adquiere tus entradas</h2>
        </SimplePaddedCard>
        <SimplePaddedCard>
          <div className="flex flex-col divide-y gap-8">
            <div className="flex flex-col md:flex-row gap-4">
              <div className="w-full md:w-72 md:min-w-[18rem]">
                <GatsbyImage
                  alt={data.featuredImage.altText || ""}
                  imgClassName="w-full object-contain min-w-full bg-white rounded-xl overflow-hidden"
                  image={data.featuredImage.localFile.childImageSharp.gatsbyImageData}
                />
              </div>
              <div className="flex flex-col gap-3">
                <PriceLayout
                  reduced={data.variants[variant]?.price * quantity}
                  original={data.variants[variant]?.compareAtPrice * quantity}
                />
                <h2 className="text-xl font-bold">{data.title}</h2>
                <p>{data.description}</p>
                <div className="flex flex-col sm:flex-row gap-y-1 gap-x-4 sm:items-center">
                  <span className="">Entradas</span>
                  <div className="max-w-[12rem]">
                    <Counter min={1} max={capacity} value={quantity} setValue={setQuantity} />
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row gap-y-1 gap-x-4 sm:items-center">
                  <span className="">Duración</span>
                  <div className="flex flex-wrap gap-2">
                    {timeOptions.map((title, i) => (
                      <SelectionPill
                        selected={title === selectedDuration}
                        label={title}
                        onClick={() => setSelectedDuration(timeOptions[i])}
                        key={i}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4 pt-8">
              <h3 className="font-bold">Selecciona la fecha y hora</h3>
              <div className="flex flex-col sm:flex-row items-center sm:items-start gap-4">
                <DatePicker
                  holidays={getHolidays()}
                  setDate={setDate}
                  start={startDate}
                  end={endDate}
                />
                <div className="px-2 w-full h-full grow rounded-lg overflow-hidden">
                  {slots.length > 0 ? (
                    <TimePicker slots={slots} seats={quantity} onSelect={setTime} />
                  ) : (
                    <p className="w-full text-center">No hay horarios disponibles</p>
                  )}
                </div>
              </div>
              <div className="w-full sm:w-72 max-w-full mx-auto">
                <PastelButton
                  disabled={!time || loading}
                  onClick={() => {
                    if (!date) return
                    const { now, selected, today } = getSelectedAndToday(date, leadTime)
                    if (
                      selected < today ||
                      (selected.valueOf() === today.valueOf() &&
                        checkIfPastHour(time.start, { hh: now.getHours(), mm: now.getMinutes() }))
                    ) {
                      setError(true)
                      setRefresh(!refresh)
                      return
                    }
                    const properties = generateReservationObject({
                      eventId: events[data.variants[variant].shopifyId]?.event.toString(),
                      timezone,
                      time,
                      date,
                      operator,
                    })
                    setLoading(true)
                    addBookingToCheckout(data.variants[variant].shopifyId, quantity, properties)
                      .then(url => window.open(url, "_self"))
                      .catch(() => setLoading(false))
                  }}
                >
                  <div className="flex gap-1 justify-center items-center">
                    {loading ? (
                      <div className="flex gap-1 justify-center items-center">
                        <div className="w-6 h-6">
                          <Loader />
                        </div>
                        Cargando
                      </div>
                    ) : (
                      <>
                        <Cart />
                        <span>Reservar</span>
                      </>
                    )}
                  </div>
                </PastelButton>
              </div>
            </div>
          </div>
        </SimplePaddedCard>
      </div>
      {error && (
        <Toast type="error" onHide={() => setError(false)}>
          El horario ya no está no disponible
        </Toast>
      )}
    </LayoutSection>
  )
}

export default ReservationLayout
