import fetch from "isomorphic-fetch"
import { useState, useEffect, useCallback } from "react"
import { dayToNumber, leadTimeToMinutes, parsePeriod } from "../util/data"
import { zeroPad } from "../util/format"

const useReservations = ({ productId, userId }) => {
  const end = new Date()
  end.setDate(end.getDate() + 14)
  const [events, setEvents] = useState({})
  const [resolution, setResolution] = useState(60)
  const [operator, setOperator] = useState("")
  const [employeeId, setEmployeeId] = useState()
  const [timezone, setTimezone] = useState("")
  const [availability, setAvailability] = useState([])
  const [specialDates, setSpecialDates] = useState({})
  const [bookings, setBookings] = useState({})
  const [capacity, setCapacity] = useState(1)
  const [leadTime, setLeadTime] = useState(0)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(end)

  const getHolidays = useCallback(
    () =>
      Object.keys(specialDates)
        .filter(
          date =>
            !(
              specialDates[date][0].start.hh ||
              specialDates[date][0].start.mm ||
              specialDates[date][0].end.hh ||
              specialDates[date][0].end.mm
            )
        )
        .map(holiday => new Date(holiday + "T00:00:00")),
    [specialDates]
  )

  const checkDay = useCallback(
    async date => {
      if (!Object.keys(events).length) return
      if (bookings[date]) return
      // Request the API if not memoized yet
      const [aaaa, mm, dd] = [date.getFullYear(), date.getMonth() + 1, date.getDate()].map(zeroPad)
      const formattedDay = [aaaa, mm, dd].join("-")

      const eventCollection = new Set()

      Object.keys(events).forEach(
        variantId =>
          !eventCollection.has(events[variantId].event) &&
          eventCollection.add(events[variantId].event)
      )
      let event
      for (let variantId in events) {
        event = events[variantId].event
        break
      }

      const response = await fetch(
        `https://servicify-appointments.herokuapp.com/api/events/${event}/employees/busy-slots`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
          body: JSON.stringify({
            employeeIds: [employeeId],
            formattedDay,
          }),
        }
      )
      const data = await response.json()
      if (!data[employeeId]) return

      const tempBookings = {}
      for (let booking of data[employeeId]) {
        const startDate = new Date(booking.date.startDateString)
        startDate.setMinutes(startDate.getMinutes() + startDate.getTimezoneOffset())
        if (bookings[startDate]) continue
        const start = new Date(booking.date.startTimeString).toTimeString().slice(0, 5)
        const [end, ampm] = booking.date.endTime.split(" ")
        const [hh] = end.split(":")
        const endString = ampm === "pm" && hh < 12 ? end.replace(hh, parseInt(hh) + 12) : end
        const reservation = parsePeriod(`${start}-${endString}`)
        if (!tempBookings[startDate]) tempBookings[startDate] = []
        tempBookings[startDate].push({ ...reservation, quantity: booking.quantity })
      }
      if (Object.keys(tempBookings).length) setBookings({ ...bookings, ...tempBookings })
    },
    [bookings, employeeId, events]
  )

  useEffect(() => {
    // First, we get all the events
    fetch(`https://servicify-appointments.herokuapp.com/api/storefront/users/${userId}/events`)
      .then(resp => resp.json())
      .then(data => {
        const events = {}
        for (let line of data)
          if (
            line.product.id.toString() ===
            productId.toString().replace("gid://shopify/Product/", "")
          )
            for (let variant of line.productVariants)
              events[variant.admin_graphql_api_id] = {
                event: line.id,
                duration: line.duration,
              }
        setEvents(events)
      })
  }, [productId, userId])

  useEffect(() => {
    if (!Object.keys(events).length) return
    // Third, ask for the scheduled times for one event
    // For that, we get the first available variant
    const variantId = Object.keys(events)[0].replace(/^.*\/+?/, "")
    fetch(
      `https://servicify-appointments.herokuapp.com/api/products/${productId
        .toString()
        .replace("gid://shopify/Product/", "")}/events?variantId=${variantId}`
    )
      .then(resp => resp.json())
      .then(
        ({
          employees: [{ regularHours, specialHours, timezone, firstName, lastName, id }],
          numAttendees,
          resolution,
          leadTime,
          startAt,
          bookMinutesIntoFuture,
          endAt,
        }) => {
          const regular = new Array(7)
          for (let day in regularHours) {
            const dayNumber = dayToNumber[day]
            regular[dayNumber] = regularHours[day].map(parsePeriod)
          }
          setAvailability(regular)
          const special = {}
          for (let day in specialHours) {
            special[day] = specialHours[day].map(parsePeriod)
          }
          setStartDate(new Date(startAt))
          if (bookMinutesIntoFuture) {
            const end = new Date()
            end.setMinutes(end.getMinutes() + bookMinutesIntoFuture)
            setEndDate(end)
          } else setEndDate(new Date(endAt))
          setSpecialDates(special)
          setCapacity(numAttendees)
          setTimezone(timezone)
          setOperator(`${firstName} ${lastName} - ${id}`)
          setEmployeeId(parseInt(id))
          setResolution(resolution)
          setLeadTime(leadTime ? leadTimeToMinutes(leadTime) : 0)
        }
      )
  }, [events, productId])

  return {
    events,
    bookings,
    getHolidays,
    capacity,
    availability,
    specialDates,
    timezone,
    operator,
    checkDay,
    employeeId,
    resolution,
    leadTime,
    startDate,
    endDate,
  }
}

export default useReservations
