import React, { useEffect, useState } from "react"
import { SelectionPill } from "../common/layout"

const TimePicker = ({ slots, onSelect, seats }) => {
  const [selected, setSelected] = useState(-1)

  useEffect(() => {
    if (selected >= 0 && slots[selected].capacity >= seats) return onSelect(slots[selected])
    onSelect()
  }, [selected, seats, slots, onSelect])

  useEffect(() => {
    if (selected >= 0) return
    const selection = slots.findIndex(slot => slot.capacity >= seats)
    if (selection >= 0) setSelected(selection)
  }, [slots, seats, selected])

  return (
    <section className="px-px grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 items-start gap-2 rounded-lg sm:overflow-y-scroll sm:max-h-96 w-full grow text-gray-700">
      {slots?.map((slot, i) => (
        <SelectionPill
          selected={selected === i}
          label={`${slot.start.hh.toString().padStart(2, "0")}:${slot.start.mm
            .toString()
            .padStart(2, "0")} - ${slot.end.hh.toString().padStart(2, "0")}:${slot.end.mm
            .toString()
            .padStart(2, "0")}`}
          onClick={() => setSelected(i)}
          disabled={slot.capacity < seats}
          key={i}
          title={`${slot.capacity} disponibles`}
        />
      ))}
    </section>
  )
}

export default TimePicker
