import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/common/layout"
import HeadGenerator from "../components/common/seo"
import ReservationLayout from "../components/reservation"
import { getProductUrl } from "../util/data"

const ReservaPage = ({ data: { product } }) => {
  return (
    <Layout>
      <ReservationLayout data={product} />
    </Layout>
  )
}

export default ReservaPage

export const Head = ({ data: { product } }) => {
  return (
    <HeadGenerator
      data={{
        title: product.title,
        description: product.description,
        url: getProductUrl(product.handle),
        image: {
          srcWebp: product.featuredImage.localFile.childImageSharp.seoImageData.images.fallback.src,
          width: product.featuredImage.localFile.childImageSharp.seoImageData.width,
          height: product.featuredImage.localFile.childImageSharp.seoImageData.height,
          altText: product.featuredImage.altText,
        },
      }}
      largeSummaryImage
    />
  )
}

export const query = graphql`
  query {
    product: shopifyProduct(shopifyId: { eq: "gid://shopify/Product/8018929877283" }) {
      shopifyId
      title
      description
      options {
        name
        values
      }
      featuredImage {
        altText
        localFile {
          childImageSharp {
            seoImageData: gatsbyImageData(formats: [WEBP], width: 630)
            gatsbyImageData(placeholder: TRACED_SVG, formats: [WEBP], sizes: "500w 200w 100w")
          }
        }
      }
      variants {
        title
        price
        compareAtPrice
        shopifyId
        selectedOptions {
          name
          value
        }
      }
    }
  }
`
