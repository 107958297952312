import React, { useState } from "react"
import { useCallback } from "react"
import { useEffect } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "../../styles/datepicker.css"
import { LeftArrow, RightArrow } from "../common/icons"

const Picker = ({ holidays, setDate, start, end }) => {
  const now = new Date()
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  const [currentlySelected, setCurrentlySelected] = useState(tomorrow)

  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ]
  const years = [now.getFullYear(), now.getFullYear() + 1]

  useEffect(() => {
    setDate(currentlySelected)
  }, [setDate, currentlySelected])

  useEffect(() => {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    setCurrentlySelected(tomorrow < start ? start : tomorrow)
  }, [start])

  const getDayClassName = useCallback(
    (className, day) => {
      if (day.toDateString() === currentlySelected.toDateString())
        return className + " bg-white border border-sky-500 bg-sky-50 font-bold"
      return className + " hover:bg-sky-50 focus-visible:bg-sky-50"
    },
    [currentlySelected]
  )

  const minDate = now < start ? start : now

  return (
    <DatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="flex justify-between px-2 items-center">
          <button
            onClick={decreaseMonth}
            className="hover:text-sky-400 focus-visible:text-sky-400 transition-colors duration-300"
            disabled={prevMonthButtonDisabled}
            title="Retroceder un mes"
          >
            <LeftArrow />
          </button>
          <div className="flex gap-2 font-semibold">
            <select
              className="cursor-pointer hover:text-sky-400 focus-visible:text-sky-400 transition-colors duration-300 appearance-none bg-transparent text-center"
              value={months[date.getMonth()]}
              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
            >
              {months.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              className="cursor-pointer hover:text-sky-400 focus-visible:text-sky-400 transition-colors duration-300 appearance-none bg-transparent text-center"
              value={date.getFullYear()}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <button
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            className="hover:text-sky-400 focus-visible:text-sky-400 transition-colors duration-300"
            title="Avanzar un mes"
          >
            <RightArrow />
          </button>
        </div>
      )}
      selected={currentlySelected}
      onChange={date => setCurrentlySelected(date)}
      calendarStartDay={1}
      calendarContainer={({ className, children }) => (
        <div
          className={`${className} p-2 bg-white rounded-lg border border-gray-200 text-gray-700`}
        >
          {children}
        </div>
      )}
      dayClassName={day =>
        getDayClassName(
          "transition-colors duration-300 rounded-lg text-gray-700 xs:w-10 xs:leading-10",
          day
        )
      }
      minDate={minDate}
      maxDate={end}
      inline
      fixedHeight
      excludeDates={holidays}
      locale={{
        localize: {
          day: n => ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"][n],
          month: n => months[n],
        },
        formatLong: {
          date: () => "mm/dd/yyyy",
        },
      }}
    />
  )
}

export default Picker
